table {
  border-collapse: saperate !important;
  border-spacing: 0;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

table tr th,
table tr td {
  margin: 0;
  border-right: 1px solid hsl(210 27% 85%);
  border-bottom: 1px solid hsl(210 27% 85%);
  /* padding: 5px; */
}
table tr th:first-child,
table tr td:first-child {
  margin: 0;
  border-left: 1px solid hsl(210 27% 85%);
}
table tr th {
  /* background: #eee;*/

  margin: 0;
  border-top: 1px solid hsl(210 27% 85%);
  text-align: left;
}
table tr td {
  /*background: #fff;*/
}
/* top-left border-radius */
table tr:first-child th:first-child {
  margin: 0;
  padding: 8px 7px;
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  margin: 0;
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  margin: 0;
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  margin: 0;
  border-bottom-right-radius: 6px;
}

[data-theme="dracula"] table tr th {
  border-color: rgb(229 231 235);
}
[data-theme="dracula"] table tr td {
  border-color: rgb(229 231 235);
}

[data-theme="light"] table {
  border-color: hsl(210 27% 85%);
}
